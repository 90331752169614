/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../Components/Layout/Layout';
import SEO from '../Components/seo';

// markup
const VolunteerPage = () => (
  <>
    <SEO title="Volunteer" />
    <Layout page="Volunteer">
      <main id="main">
        <section id="contact" className="wow fadeInUp">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <StaticImage src="../images/signup-img.jpg" alt="" className="img-fluid" />
              </div>
              <div className="col-lg-6 col-12">
                <div className="form">
                  <form method="post" className="contactForm" data-netlify="true" name="volunteer" action="/success">
                    <input type="hidden" name="volunteer" value="Volunteer Form" />
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="name">Name</label>
                        <input id="name" type="text" name="name" className="form-control" required />

                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="surname">Surname</label>
                        <input id="surname" type="text" className="form-control" name="surname" required />

                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="phone-number">Phone Number</label>
                        <input id="phone-number" type="number" name="phone-number" className="form-control" required />

                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="email">Email</label>
                        <input id="email" type="email" className="form-control" name="email" required />

                      </div>
                    </div>
                    <div className="form-group">
                      <label name="short-bio">Short bio</label>
                      <textarea id="short-bio" className="form-control" name="short-bio" rows="5" required />

                    </div>
                    <div className="text-center"><button type="submit" value="send" className="contact-btn">Join Us</button></div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </section>
      </main>
    </Layout>
  </>

);

export default VolunteerPage;
